import React from 'react';
import Nav from './nav';

export default function Header() {
    return (
        <header>
            {/* <h1>Zach</h1> */}
            <Nav />
        </header> 
    )
}